import React from 'react'
import Location from 'src/components/Typography/Location'
import LocationGroup from 'src/components/LocationGroup'
import Wrapper from './style'

interface Location {
  location: string
  slug: string
  date?: any
}

interface Props {
  pathname: string
  locations: Location[]
  archive?: boolean
  announcement?: boolean
}

const LocationsList = (props: Props) => {
  const { pathname, locations, archive, announcement } = props

  return (
    <Wrapper>
      <div>
        {locations.map( item => (
          <LocationGroup
            key={item.slug}
            extend={announcement || !archive}
            item={item}
            pathname={pathname}
          />
        ))}
      </div>
    </Wrapper>
  )
}

export default LocationsList
