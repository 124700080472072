import React from 'react'
import ProgressBar from 'src/components/ProgressBar'
import Navbar from 'src/sections/Navbar'
import Breadcrumbs from 'src/sections/Breadcrumbs'
import LocationsList from 'src/sections/LocationsList'
import Layout from 'src/layout'
import SEO from 'src/components/SEO'
import createDateComponent from 'src/utils/createDateComponent'

const createLocations = (archives: any[]) => {
  return archives.map(archive => {
    return {
      location: archive.city,
      slug: archive.slug,
      date: {
        date_start: createDateComponent(archive.date.start),
        date_end: createDateComponent(archive.date.end)
      }
    }
  })
}

const ArchivesPage = (props: any) => {
  const exposition = props.pageContext.exposition
  const locations = createLocations(exposition.archives)

  return (
    <Layout>
      <SEO url={props.location.href} />
      <ProgressBar />
      <main>
        <div className="desktop">
          <Navbar />
        </div>
        <Breadcrumbs
          archive
          url={exposition.slug}
          title={exposition.title}
        />
        <LocationsList
          archive
          pathname={props.location.pathname}
          locations={locations}
        />
      </main>
    </Layout>
  )
}

export default ArchivesPage
