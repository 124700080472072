import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  height: 100%;
  width: ${props => props.theme.containerWidth.sm};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  flex-flow: column;
  flex: 1 1 auto;

  ${up('tablet')} {
    overflow-x: hidden;
    width: 100%;
    /* overflow: hidden; */

    /* &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 5rem;
      top: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(
        to bottom,
        ${props => props.theme.colors.backgroundLight} 30%,
        rgba(244,243,242,0));
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 5rem;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(
        to top,
        ${props => props.theme.colors.backgroundLight} 30%,
        rgba(244,243,242,0));
      z-index: 2;
    } */
  }

  ${up('desktop')} {
    /* &::before, &::after {
      height: 10rem;
    } */
  }

  ${up('largeDesktop')} {
    /* &::before, &::after {
      height: 20rem;
    } */
  }

  & > div {
    width: 100%;
    height: 100%;
    padding: 5rem 0;
    display: flex;
    flex-flow: column;
    align-items: center;

    ${up('tablet')} {
      width: calc(100vw + 15px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 3rem 0;
      flex-grow: 1;

      & > div {
        flex-grow: 0;
      }
    }

    ${up('desktop')} {
      padding: 4.5rem 0;
    }

    ${up('largeDesktop')} {
      padding: 10rem 0;
    }
  }

`

export default Wrapper
